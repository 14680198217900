<template>
  <div class="create-dependant-on form-content content">
    <div v-if="!isSelectTableVisible">
      <!--  Header    -->
      <CRow class="p-0">
        <CCol col="12" class="d-flex">
          <h3 class="mb-3 bread-content">{{stepNumber}}. {{ texts.createPage.dependantOn.title }}</h3>
        </CCol>
      </CRow>
      <SwitchRow
        md-label='2'
        lg-label='2'
        sm-label='2'
        :label="form.label"
        :toolTipText="form.tooltip"
        :valueProp="isMission"
        :disabled="form.disabled"
        @swipeHandler="isMission = $event === 'right'"
        @updateHandler="isMission = $event"
      />
      <DepResultTable
        @toSelectTable="toSelectTable"
        @resetValidation="resetDependantValidation"
        @updateCurrentTypes="updateCurrentTypes"
        @updateData="handleUpdateDependantOnData"
        :propData="dependantOnData"
        :isValid="isValid"
        :currentTypesProps="currentTypes"
        :label="texts.createPage.dependantOn.dependantLabel"
        :buttonLabel="texts.createPage.dependantOn.buttonLabel"
        :errorMessage="texts.createPage.dependantOn.errorMessage"
        :disabled="isFieldDisabled || isMission"
        :isKey="isKey"
      />
    </div>
    <DepSelectTable
      @toResultTable="toResultTable"
      @updateSelectData="achSelectData"
      :selectedData="selectedData"
      :title="stepNumber + '.1. ' + texts.createPage.dependantOn.depSelectTableTitle"
      :fields="fields"
      :formData="formData"
      :toolTipText="descriptions.dependantOn"
      v-else
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DepSelectTable from '@/shared/components/DepTables/DepSelectTable';
import DepResultTable from '@/shared/components/DepTables/DepResultTable';
import SwitchRow from '@/shared/components/SwitchRow';
import { isAchFieldDisabled } from "@/utils/validationUtils";
import { achievements } from '@/config/descriptions/achievements';
import { checkNullItem } from '@/helpers/checkNullItem';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import { pageConfig } from '@/config';

export default {
  name: 'CreateDependantOn',
  components: {
    DepResultTable,
    DepSelectTable,
    SwitchRow,
  },
  props: {
    dependantOnData: Object,
    isValid: Boolean,
    status: {
      type: String,
      default: 'Ready'
    },
    stepNumber: {
      type: Number,
      default: 3
    },
    isKey: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      isSelectTableVisible: false,
      selectedData: [],
      currentTypes: {},
      isMission: false,
      fields: [
        "select",
        "id",
        "created",
        "name",
        "description",
        "category",
        "constraints",
      ],
      form: {
        "key": "mission",
        "label": "Mission",
        "tooltip": "",
        "type": "SWITCH",
        "model": "CreateAchievementRequest",
        "disabled": false,
        "options": null,
        "multiple": false,
        "required": false,
        "showKey": false
      },
      formData: {
        isMission: false,
        dependantOn: {
          must: [],
          mustNot: [],
          should: [],
          shouldMatchAtLeast: 0,
        }
      },
      descriptions: {
        dependantOn: achievements.list.create.dependantOn.dependantOn,
      },
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  computed: {
    isFieldDisabled() {
      return isAchFieldDisabled(this.status);
    },
  },
  watch: {
    isMission(val) {
      this.formData.isMission = val;
      this.$emit('updateIsMission', val);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleGetAchievementsByQuery']),
    initialize() {
      this.handleGetAchievementsByQuery({
        queryRequest: {
          sortBy: this.sortBy,
          limit: this.itemsPerPage,
          skip: 0
        }
      });
      this.currentTypes = this.dependantOnData.currentTypes;
      this.formData = this.dependantOnData.formData;
      this.selectedData = this.dependantOnData.selectedData;
      this.isMission = this.dependantOnData.isMission;
    },
    toSelectTable() {
      this.isSelectTableVisible = true;
    },
    toResultTable() {
      this.isSelectTableVisible = false;
    },
    achSelectData({selectedData, formData}) {
      selectedData.map((item) => {
        item.valid = formData.dependantOn.mustNot.includes(item.id) || formData.dependantOn.must.includes(item.id) || formData.dependantOn.should.includes(item.id);
        return item;
      })
      this.selectedData = checkNullItem(selectedData);

      this.formData = formData;
      this.$emit('updateDependantOnData', {...this.dependantOnData,formData, selectedData});
    },
    handleUpdateDependantOnData(val) {
      this.selectedData = checkNullItem(val.selectedData);
      this.$emit('updateDependantOnData', {...this.dependantOnData, formData: val.formData, selectedData: val.selectedData});
    },
    resetDependantValidation() {
      this.$emit('resetDependantValidation');
    },
    updateCurrentTypes(val) {
      this.currentTypes = val;
      this.$emit('updateCurrentTypes', val);
    }
  }
}
</script>
